import { APP_NAME, INFO_EMAIL } from '@glow/shared/lib/constants';
import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app';
import type { Script, Noscript } from '@unhead/vue';

export default defineNuxtPlugin(() => {
  const { clientBaseUrl, apiBaseUrl, staticUrl } = useRuntimeConfig().public;
  const description =
    'Сервис поиска услуг-красоты. Соединяем мастеров и клиентов. Автоматизируем твою работу от начала и до конца';

  const gtmScript: Script = {
    children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-KKMGHVP');`,
    tagPosition: 'head',
  };
  const schemaOrgScript: Script = {
    type: 'application/ld+json',
    children: JSON.stringify({
      '@context': 'http://www.schema.org',
      '@type': 'Organization',
      name: APP_NAME,
      url: clientBaseUrl,
      logo: `${staticUrl}/images/logo.svg`,
      description,
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'г. Чебоксары, ул. Ленинского Комсомола, дом 39',
        addressLocality: 'Чебоксары',
        postalCode: '428029',
        addressCountry: 'Россия',
      },
      openingHours: 'Mo, Tu, We, Th, Fr 10:00-22:00 Sa, Su 10:00-20:00',
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: INFO_EMAIL,
      },
    }),
  };
  const gtmNoScript: Noscript = {
    children: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KKMGHVP"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
    tagPosition: 'bodyOpen',
  };

  useHead({
    titleTemplate: (title?: string) => `${title} | ${APP_NAME}`,
    htmlAttrs: { lang: 'ru' },
    script: !process.dev ? [gtmScript, schemaOrgScript] : [],
    noscript: !process.dev ? [gtmNoScript] : [],
    link: [
      { rel: 'manifest', href: '/manifest.webmanifest' },
      { rel: 'preconnect', href: apiBaseUrl },
      { rel: 'dns-prefetch', href: apiBaseUrl },
    ],
  });
  const route = useRoute();
  useServerSeoMeta({
    appleMobileWebAppCapable: 'yes',
    appleMobileWebAppStatusBarStyle: 'black',
    themeColor: '#1d1d24',
    description,
    ogDescription: description,
    ogLocale: 'ru_RU',
    ogType: 'website',
    ogSiteName: APP_NAME,
    ogImage: `${clientBaseUrl}/og.png`,
    ogImageWidth: 1200,
    ogImageHeight: 630,
    ogUrl: toRef(() => new URL(route.fullPath, clientBaseUrl).toString()),
  });
});
