<script setup lang="ts">
import { LoaderOverlay } from '@glow/shared/components/misc';
import { useToasts } from '@glow/shared/store/toasts';
import { AuthModalHandler } from '@glow/shared/features/auth';

const { showErrorToast } = useToasts();
onErrorCaptured((e: unknown) => {
  // eslint-disable-next-line no-console
  console.error(e);
  if (e instanceof Error)
    showErrorToast({ title: 'Произошла ошибка', text: e.message });
});
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <AuthModalHandler />
    <LoaderOverlay />
  </div>
</template>
